<template>
  <div>
    <h2 class="mb-4">Produktionsübersicht</h2>

    <div class="mb-4 flex gap-4 border-b border-gray-900">
      <label>
        Aktuelle Woche:
        <input
          type="number"
          max="53"
          min="1"
          v-model="currentWeek"
          style="max-width: 100px;"
        />
      </label>
      <label class="mb-4">
        Arbeitsplatz:
        <select v-model="selectedArbeitsgang">
          <option
            v-for="arbeitsgang in arbeitsgaenge"
            v-bind:key="arbeitsgang.id"
            v-bind:value="arbeitsgang.value"
            >{{ arbeitsgang.name }}</option
          >
        </select>
      </label>
    </div>

    <dashboard-element>
      <dashboard-head title="Arbeitsaufträge" />
      <dashboard-table class="p-3">
        <div v-if="auftraegeFuerAg.length > 0" class="flex flex-col gap-2">
          <dashboard-card
            v-for="auftrag in auftraegeFuerAg"
            :title="auftrag.belegnr"
            :content="`${auftrag.form} ${auftrag.menge} Stück`"
            :key="auftrag.id"
            :link="`/auftrag/${auftrag.auftragsnr}`"
          />
        </div>
        <div v-else>
          <h2>Keine Aufträge vorhanden</h2>
        </div>
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import DashboardCard from "../components/dashboard/DashboardCard.vue";
import DashboardElement from "../components/dashboard/DashboardElement.vue";
import DashboardHead from "../components/dashboard/DashboardHead.vue";
import DashboardTable from "../components/dashboard/DashboardTable.vue";
import moment from "moment";

export default {
  components: {
    DashboardElement,
    DashboardHead,
    DashboardTable,
    DashboardCard
  },
  name: "Arbeitsplaetze",
  data() {
    return {
      currentWeek: moment().week(),
      selectedArbeitsgang: "saegen",
      arbeitsgaenge: [
        {
          id: 2,
          name: "Sägen",
          value: "saegen"
        },
        {
          id: 3,
          name: "Vordrehen",
          value: "vordrehen"
        },
        {
          id: 4,
          name: "Mischen",
          value: "mischen"
        },
        {
          id: 5,
          name: "Pressen",
          value: "pressen"
        },
        {
          id: 6,
          name: "Fertigdrehen",
          value: "fertigdrehen"
        },
        {
          id: 7,
          name: "Fräsen",
          value: "fraesen"
        },
        {
          id: 8,
          name: "Strahlen",
          value: "strahlen"
        },
        {
          id: 9,
          name: "Lasern",
          value: "lasern"
        }
      ]
    };
  },
  methods: {
    createProduct() {
      console.log("NEW PRODUCT NOW PLZZZ");
    }
  },
  computed: {
    auftraegeFuerAg() {
      const currentAg = this.arbeitsgaenge.find(
        ag => ag.value === this.selectedArbeitsgang
      );

      const filteredAuftraege = this.aktuelleAuftraege.filter(
        auftrag => auftrag.ag[0].id === currentAg.id && auftrag.ag[0].current
      );

      console.log(filteredAuftraege);

      return filteredAuftraege;
    },
    aktuelleAuftraege() {
      return this.$store.getters.getPaByKw(parseInt(this.currentWeek));
    }
  }
};
</script>
